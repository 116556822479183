<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value,ip:valueLine.target.ip,host:valueLine.target.host,port:valueLine.target.port  }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';

export default {
  components: {fieldsRows},
  data() {
    return {
      localIpv: [['ipv4', 'IPv4'], ['ipv6', 'IPv6']],
      isIPV4: true,
    };
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
             ip: {
                type: 'select',
                associatedOption: this.localIpv,
                name: 'IP Version',
                fieldAttrInput: {class: 'required'},
                colAttrs: {cols: 1},
              },
               host: {
                type: 'text',
                name: 'Host',
                inputFieldFormatter: 'ip4ip6ValidatedInput',
                colAttrs: {style: 'max-width:378px', class: ''},
              },
              port: {
                type: 'number',
                name: 'Port',
                colAttrs: {cols: 1},
              },
              count: {
                type: 'number',
                name: 'Count',
                colAttrs: {cols: 1},
              },
              size: {
                type: 'number',
                name: 'Size',
                colAttrs: {cols: 1},
              },
              interval: {
                type: 'text',
                name: 'Interval',
                colAttrs: {cols: 1},
                fieldAttrInput: {class: 'validatePingInterval'},
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
       if (this.isIPV4) {
        templateContent.fields.pingLine.fields.tos = {
          type: 'text',
          name: 'IPP/DSCP/TOS',
          colAttrs: {cols: 2},
          fieldAttrInput: {class: 'validateHexByte'},
        };
      }
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
     // console.log(index, value)
      if (index === 'ip') {
        this.isIPV4 = value === 'ipv4';
      }
      let localValue = {...this.value};
      if (index !== 'host' && index !== 'ip' && index !== 'port') {
        this.$set(localValue, index, value);
        this.$emit('input', localValue);
      } else if (index === 'host' || index === 'ip' || index === 'port') {
        this.$set(this.valueLine['target'], index, value);
        this.$emit('stored-value-changed', this.valueLine);
      }
    },
  },
};
</script>